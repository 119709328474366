import React from 'react';
import './CitiesEditor.scss';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { updateCity } from '../../store/citiesSlice';
import {
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import * as api from '../../api/api';

const CitiesEditor = () => {
  const dispatch = useDispatch();

  const fetchCities = async () => {
    try {
      const citiesData = await api.getAllCities();
      setCities(citiesData);
    } catch (error) {
      console.error('Ошибка загрузки городов:', error);
    }
  };

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState('');
  const [cityId, setCityId] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const cityHandler = (e) => {
    setCity(e.target.value);
  };

  const editBtnHandler = async (id) => {
    setIsLoading(true);
    const data = new FormData();
    data.append('name', city);
    try {
      await dispatch(updateCity({ data: data, id }));
      setCity('');
      setCityId('');
      fetchCities();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBtnHandler = async (id) => {
    try {
      const response = await api.deleteCity(
        {
          method: 'DELETE',
          credentials: 'include',
        },
        id
      );
      setCityId('');
      fetchCities();
    } catch (error) {
      alert('Сначала необходимо удалить связанные с городом открытия');
    }
  };

  const onChangeCity = (event, newValue) => {
    setCity(newValue?.name || '');
    setCityId(newValue?.id || '');
  };

  useEffect(() => {
    fetchCities();
  }, []);

  return (
    <section className="cities-editor">
      <h2 className="cities-editor__title">Города</h2>
      <div className="cities-editor__list">
        <Autocomplete
          options={cities}
          getOptionLabel={(option) => option?.name || 'Населенный пункт'}
          value={city ? { name: city } : null}
          onChange={onChangeCity}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
              }}
            />
          )}
          sx={{ width: 500, borderRadius: 20 }}
        />
      </div>
      {cityId && (
        <div className="cities-editor__edit">
          <form>
            <TextField
              variant="outlined"
              sx={{
                width: 500,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
              onChange={cityHandler}
              value={city}
            />
            <Button
              onClick={() => editBtnHandler(cityId)}
              disabled={isLoading || !city.trim()}
              variant="contained"
              sx={{
                width: 300,
                background: '#2c2c2c',
                boxShadow: 'none',
                borderRadius: '8px',
                height: 50,
                textTransform: 'none',
                fontSize: 16,
                transition: 'opacity 0.3s ease',
                '&:hover': {
                  boxShadow: 'none',
                  opacity: 0.7,
                },
                '&.Mui-disabled': {
                  opacity: 0.2,
                  background: '#2c2c2c',
                  color: '#fff',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Сохранить изменения'
              )}
            </Button>
            <Button
              onClick={() => deleteBtnHandler(cityId)}
              disabled={isLoading || !city.trim()}
              variant="outlined"
              startIcon={<DeleteIcon />}
              sx={{
                width: 300,
                background: 'transparent',
                boxShadow: 'none',
                borderRadius: '8px',
                height: 50,
                textTransform: 'none',
                fontSize: 16,
                transition: 'opacity 0.3s ease',
                color: '#FF4649',
                borderColor: '#FF4649',
                '&:hover': {
                  boxShadow: 'none',
                  opacity: 0.7,
                },
                '&.Mui-disabled': {
                  opacity: 0.2,
                  background: '#2c2c2c',
                  color: '#fff',
                },
              }}
            >
              Удалить
            </Button>
          </form>
        </div>
      )}
    </section>
  );
};

export default CitiesEditor;
