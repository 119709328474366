import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import React, { useRef, useState } from 'react';
import './MapEdit.scss';
import MapEditSvg from '../MapEdit/MapEditSvg';

const Controls = ({ zoomIn, zoomOut }) => (
  <div className="map__controls">
    <button
      onClick={() => zoomOut()}
      className="map__control-btn map__control-btn--minus"
    >
      <img src="./../img/icons/icon-minus.svg" alt="Минус" />
    </button>
    <button
      className="map__control-btn map__control-btn--plus"
      onClick={() => zoomIn()}
    >
      <img src="./../img/icons/icon-plus.svg" alt="Плюс" />
    </button>
  </div>
);

function MapEdit2({ setCoordinateX, setCoordinateY }) {
  const transformWrapperRef = useRef(null);
  const [point, setPoint] = useState(null);

  const zoomIn = () => {
    if (transformWrapperRef.current) {
      const { zoomIn } = transformWrapperRef.current;
      zoomIn(0.5, 600, 'easeOut');
    }
  };

  const zoomOut = () => {
    if (transformWrapperRef.current) {
      const { zoomOut } = transformWrapperRef.current;
      zoomOut(0.5, 600, 'easeOut');
    }
  };

  const handleMapClick = (event) => {
    const wrapperBounds = event.currentTarget.getBoundingClientRect();

    const x =
      ((event.clientX - wrapperBounds.left) / wrapperBounds.width) * 100;
    const y =
      ((event.clientY - wrapperBounds.top) / wrapperBounds.height) * 100;

    setPoint({ x, y });
    setCoordinateX(x);
    setCoordinateY(y);
  };

  return (
    <div className="map-editor__wrapper">
      <Controls zoomIn={zoomIn} zoomOut={zoomOut} />

      <TransformWrapper
        initialScale={1}
        maxScale={80}
        initialPositionX={0}
        initialPositionY={0}
        minScale={1}
        ref={transformWrapperRef}
      >
        {(utils) => (
          <React.Fragment>
            <TransformComponent>
              <div className="map-container" onClick={handleMapClick}>
                <MapEditSvg />

                {point && (
                  <div
                    className="map-editor__new-point"
                    style={{
                      left: `${point.x}%`,
                      top: `${point.y}%`,
                    }}
                  ></div>
                )}
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );
}

export default MapEdit2;
