import { useEffect, useState } from 'react';
import './AdminAuth.scss';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../store/userSlice';

function AdminAuth() {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [loginDirty, setLoginDirty] = useState(false);
  const [passwordDirty, setPasswordDirty] = useState(false);
  const [loginError, setLoginError] = useState('Поле обязательно к заполнению');
  const [passwordError, setPasswordError] = useState(
    'Поле обязательно к заполнению'
  );
  const [formValid, setFormValid] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'login':
        setLoginDirty(true);
        break;
      case 'password':
        setPasswordDirty(true);
        break;
    }
  };

  const loginHandler = (e) => {
    setLogin(e.target.value);
    if (e.target.value.length === 0) {
      setLoginError('Поле обязательно к заполнению');
    } else {
      setLoginError('');
    }
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
    if (e.target.value.length === 0) {
      setPasswordError('Поле обязательно к заполнению');
    } else {
      setPasswordError('');
    }
  };

  useEffect(() => {
    if (loginError || passwordError) {
      setFormValid(false);
    } else {
      setFormValid(true);
    }
  }, [loginError, passwordError]);

  const { status, user, authErrorMessage } = useSelector((state) => state.user);

  const submitFormHandler = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('login', login);
    formData.append('password', password);

    dispatch(loginUser(formData));
  };

  useEffect(() => {
    if (status === 'resolved' && user.token && user.role === 'admin') {
      navigate('/admin/logo');
    }
  }, [status, user, navigate]);

  return (
    <div className="admin-auth">
      <div className="admin-auth__wrapper">
        <div className="admin-auth__content">
          <h2 className="admin-auth__title">Авторизация</h2>
          <div className="admin-auth__form">
            <form onSubmit={submitFormHandler}>
              <label>
                <input
                  onBlur={(e) => blurHandler(e)}
                  type="text"
                  name="login"
                  placeholder="Логин"
                  value={login}
                  onChange={(e) => loginHandler(e)}
                  className={
                    loginDirty && loginError ? 'admin-auth__input-error' : ''
                  }
                />
                <img
                  className={
                    loginDirty && loginError
                      ? 'admin-auth__icon-error is-active'
                      : 'admin-auth__icon-error'
                  }
                  src="./../img/icons/icon-error.svg"
                  alt="ошибка"
                />
              </label>
              {/* {loginDirty && loginError && (
                <div style={{ color: 'red' }}>{loginError}</div>
              )} */}
              <label>
                <input
                  onBlur={(e) => blurHandler(e)}
                  type="password"
                  name="password"
                  placeholder="Пароль"
                  value={password}
                  onChange={(e) => passwordHandler(e)}
                  className={
                    passwordDirty && passwordError
                      ? 'admin-auth__input-error'
                      : ''
                  }
                />
                <img
                  className={
                    passwordDirty && passwordError
                      ? 'admin-auth__icon-error is-active'
                      : 'admin-auth__icon-error'
                  }
                  src="./../img/icons/icon-error.svg"
                  alt="ошибка"
                />
              </label>

              {/* {passwordDirty && passwordError && <div>{loginError}</div>} */}
              <div className="admin-auth__error-message">
                {authErrorMessage}
              </div>
              <button disabled={!formValid} type="submit">
                Авторизация
              </button>
            </form>
          </div>
        </div>
        <div className="admin-auth__img">
          <img
            src="./../img/content/admin-auth/admin-auth-01.svg"
            alt="Фоновое изображение"
          />
        </div>
      </div>
    </div>
  );
}

export default AdminAuth;
