import { Routes, Route, NavLink } from 'react-router';
import * as api from '../../api/api';
import { useEffect } from 'react';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar';
import './AdminPage.scss';
import LogoEditor from '../../components/LogoEditor/LogoEditor';
import MapEditor from '../../components/MapEditor/MapEditor';
import BroadcastsEditor from '../../components/BroadcastsEditor/BroadcastsEditor';
import DiscoveriesEditor from '../../components/DiscoveriesEditor/DiscoveriesEditor';
import CitiesEditor from '../../components/CitiesEditor/CitiesEditor';

function UserPage() {
  // useEffect(() => {
  //   (async function () {
  //     const response = await api.getUserInfo();
  //   })();
  // });

  return (
    <div className="admin-page">
      <div className="container">
        <div className="admin-page__wrapper">
          <AdminSidebar />

          <div className="tabs-content">
            <Routes>
              {/* <Route path="" element={<div>Добро пожаловать!</div>} /> */}
              <Route path="logo" element={<LogoEditor />} />
              <Route path="about" element={<div>ABOUT</div>} />
              <Route path="map" element={<MapEditor />} />
              <Route path="broadcasts" element={<BroadcastsEditor />} />
              <Route path="discoveries" element={<DiscoveriesEditor />} />
              <Route path="cities" element={<CitiesEditor />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserPage;
