import { useEffect, useState } from 'react';
import './MapEditor.scss';
import MapEdit from '../Map/MapEdit';
import * as api from '../../api/api';
import MapEdit2 from '../Map/MapEdit2';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCities } from '../../store/citiesSlice';

import {
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Button,
  CircularProgress,
} from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function MapEditor() {
  const [cities, setCities] = useState([]);
  const dispatch = useDispatch();
  const [scientificFields, setScientificFields] = useState([]);
  const [category, setCategory] = useState('broadcast');
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(false);
  const [scientificField, setScientificField] = useState('');
  const [isScientificFieldDisabled, setIsScientificFieldDisabled] =
    useState(true);
  const [isCityDisabled, setIsCityDisabled] = useState(true);
  const [isSelectCityDisabled, setIsSelectCityDisabled] = useState(false);
  const [citySelectValue, setCitySelectValue] = useState('');
  const [cityBlockIsHidden, setCityBlockIsHidden] = useState(true);
  const [newCity, setNewCity] = useState('');

  const [coordinateX, setCoordinateX] = useState(null);
  const [coordinateY, setCoordinateY] = useState(null);
  const [isMapDisabled, setIsMapDisabled] = useState(true);
  const [isCardDisabled, setIsCardDisabled] = useState(true);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [videoLink, setVideoLink] = useState('');

  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  const fetchCities = async () => {
    try {
      const citiesData = await api.getAllCities();
      setCities(citiesData);
    } catch (error) {
      console.error('Ошибка загрузки городов:', error);
    }
  };

  useEffect(() => {
    setCities(fetchCities());
  }, []);

  useEffect(() => {
    async function fetchScientificFields() {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/all_scientific_fields`
      );

      const data = await response.json();
      setScientificFields(data);
      setScientificField(data[0].id);
    }
    fetchScientificFields();
  }, []);

  const categorySelectHandler = (e) => {
    setCategory(e.target.value);
  };

  const confirmCategoryHandler = () => {
    if (category === 'broadcast') {
      setIsCategoryDisabled(true);
      setIsMapDisabled(false);
    } else if (category === 'discovery') {
      setIsCategoryDisabled(true);
      setIsScientificFieldDisabled(false);
    }
  };

  const scientificFieldSelectHandler = (e) => {
    setScientificField(e.target.value);
  };

  const confirmScientificFieldHandler = () => {
    setIsScientificFieldDisabled(true);
    setIsCityDisabled(false);
  };

  const cancelScientificFieldHandler = () => {
    setIsScientificFieldDisabled(true);
    setIsCategoryDisabled(false);
  };

  const addCityBtnHandler = () => {
    setCityBlockIsHidden(!cityBlockIsHidden);
    setIsSelectCityDisabled(!isSelectCityDisabled);
  };

  const cityHandler = (e) => {
    setNewCity(e.target.value);
  };

  const confirmCityHandler = () => {
    setIsCityDisabled(true);
    if (isSelectCityDisabled && newCity !== '') {
      setIsMapDisabled(false);
    } else {
      setIsCardDisabled(false);
    }
  };

  const cancelCityHandler = () => {
    setIsCityDisabled(true);
    setIsScientificFieldDisabled(false);
  };

  const confirmMapHandler = () => {
    setIsMapDisabled(true);
    setIsCardDisabled(false);
  };

  const cancelMapHandler = () => {
    setIsMapDisabled(true);
    if (!citySelectValue && newCity === '') {
      setIsCategoryDisabled(false);
    } else {
      setIsCityDisabled(false);
    }
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const linkHandler = (e) => {
    setLink(e.target.value);
  };

  const videoLinkHandler = (e) => {
    setVideoLink(e.target.value);
  };

  const confirmCardHandler = (e) => {
    e.preventDefault();
    setIsCardDisabled(true);
    setIsAddBtnDisabled(false);
  };

  const cancelCardHandler = (e) => {
    e.preventDefault();
    setIsCardDisabled(true);
    setIsMapDisabled(false);
  };

  const addDotHandler = (e) => {
    e.preventDefault();

    setIsLoading(true);

    if (category === 'broadcast') {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('description', description);
      formData.append('link', link);
      formData.append('video_link', videoLink);
      formData.append('coordinateX', coordinateX);
      formData.append('coordinateY', coordinateY);

      (async function () {
        const result = await api.addBroadcast({
          method: 'POST',
          body: formData,
          credentials: 'include',
        });
        if (result.status === true) {
          setCoordinateX(null);
          setCoordinateY(null);
          setName('');
          setDescription('');
          setLink('');
          setVideoLink('');
          setIsAddBtnDisabled(true);
          setIsCategoryDisabled(false);
          setIsLoading(false);
          setTimeout(() => {
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
          }, 500);
        }
      })();
    } else if (category === 'discovery') {
      if (isSelectCityDisabled && newCity !== '') {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('link', link);
        formData.append('city_name', newCity);
        formData.append('video_link', videoLink);
        formData.append('coordinateX', coordinateX);
        formData.append('coordinateY', coordinateY);
        formData.append('scientific_field_id', scientificField);

        (async function () {
          const result = await api.addLocation({
            method: 'POST',
            body: formData,
          });
          if (result.status === true) {
            setCoordinateX(null);
            setCoordinateY(null);
            setName('');
            setDescription('');
            setLink('');
            setVideoLink('');
            setIsAddBtnDisabled(true);
            setIsCategoryDisabled(false);
            setTimeout(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 500);
          }
        })();
      } else {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('description', description);
        formData.append('link', link);
        formData.append('city_id', citySelectValue.id);
        formData.append('video_link', videoLink);
        formData.append('scientific_field_id', scientificField);

        (async function () {
          const result = await api.addLocation({
            method: 'POST',
            body: formData,
          });
          if (result.status === true) {
            setCoordinateX(null);
            setCoordinateY(null);
            setName('');
            setDescription('');
            setLink('');
            setVideoLink('');
            setIsAddBtnDisabled(true);
            setIsCategoryDisabled(false);
            setIsLoading(false);
            setTimeout(() => {
              window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }, 500);
          }
        })();
      }
    }
  };

  return (
    <div className="map-editor">
      <div
        className={
          isCategoryDisabled
            ? 'map-editor__select-category map-editor__step disabled'
            : 'map-editor__select-category map-editor__step'
        }
      >
        <h2 className="map-editor__step-title">Шаг 1. Выбор категории</h2>
        <div className="map-editor__categories">
          <Select
            value={category}
            sx={{
              width: 500,
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2c2c2c',
                  borderWidth: '2px',
                },
              },
              borderRadius: 3,
            }}
            defaultValue="broadcasts"
            onChange={categorySelectHandler}
          >
            <MenuItem value="broadcast">Трансляция</MenuItem>
            <MenuItem value="discovery">Открытие</MenuItem>
          </Select>
          <button
            className="map-editor__confirm-btn"
            onClick={confirmCategoryHandler}
          >
            Далее
          </button>
        </div>
      </div>
      <div
        className={
          isScientificFieldDisabled
            ? 'map-editor__select-scientific-field map-editor__step disabled'
            : 'map-editor__select-scientific-field map-editor__step '
        }
      >
        <h2 className="map-editor__step-title">Шаг 2. Выбор области науки</h2>
        <div className="map-editor__scientific-fields">
          <Select
            value={scientificField}
            sx={{
              width: 500,
              '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#2c2c2c',
                  borderWidth: '2px',
                },
              },
              borderRadius: 3,
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 300,
                },
              },
            }}
            onChange={scientificFieldSelectHandler}
          >
            {scientificFields.map((scientificField) => (
              <MenuItem key={scientificField.id} value={scientificField.id}>
                {scientificField.name}
              </MenuItem>
            ))}
          </Select>
          <div className="map-editor__btns">
            <button
              className="map-editor__back-btn"
              onClick={cancelScientificFieldHandler}
            >
              Назад
            </button>
            <button
              className="map-editor__confirm-btn"
              onClick={confirmScientificFieldHandler}
            >
              Далее
            </button>
          </div>
        </div>
      </div>
      <div
        className={
          isCityDisabled
            ? 'map-editor__select-city map-editor__step disabled'
            : 'map-editor__select-city map-editor__step '
        }
      >
        <h2 className="map-editor__step-title">
          Шаг 3. Выбор населенного пункта
        </h2>
        <div className="map-editor__city">
          <p className="map-editor__desc">
            Выберите населенный пункт из списка или добавьте новый
          </p>
          <div
            className={
              isSelectCityDisabled
                ? 'map-editor__select-city-select disabled'
                : 'map-editor__select-city-select'
            }
          >
            <Autocomplete
              options={cities}
              getOptionLabel={(option) => option?.name || 'Населенный пункт'}
              value={citySelectValue || 'Населенный пункт'}
              onChange={(event, newValue) => setCitySelectValue(newValue)}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '8px',
                      '&.Mui-focused fieldset': {
                        borderColor: '#2c2c2c',
                        borderWidth: '2px',
                      },
                    },
                  }}
                />
              )}
              sx={{ width: 500, borderRadius: 20 }}
            />
          </div>
          <p className="map-editor__desc">
            Если населенного пункта нет в списке добавьте новый
          </p>
          <button
            className="map-editor__add-city-btn"
            onClick={addCityBtnHandler}
          >
            <span>Добавить новый населенный пункт</span>{' '}
            <ArrowDropDownIcon color="red" />
          </button>
          <div
            className={
              cityBlockIsHidden
                ? 'map-editor__add-city'
                : 'map-editor__add-city is-active'
            }
          >
            <TextField
              label="Название населенного пункта"
              variant="outlined"
              sx={{
                width: 500,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',

                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
              onChange={cityHandler}
              value={newCity}
            />
          </div>
        </div>
        <div className="map-editor__btns">
          <button className="map-editor__back-btn" onClick={cancelCityHandler}>
            Назад
          </button>
          <button
            className="map-editor__confirm-btn"
            onClick={confirmCityHandler}
          >
            Далее
          </button>
        </div>
      </div>
      <div
        className={
          isMapDisabled
            ? 'map-editor__select-map map-editor__step disabled'
            : 'map-editor__select-map map-editor__step'
        }
      >
        <h2 className="map-editor__step-title">
          Шаг 3. Установка точки на карту
        </h2>
        <MapEdit2
          setCoordinateX={setCoordinateX}
          setCoordinateY={setCoordinateY}
        />
        <div className="map-editor__btns">
          <button className="map-editor__back-btn" onClick={cancelMapHandler}>
            Назад
          </button>
          <button
            className="map-editor__confirm-btn"
            onClick={confirmMapHandler}
          >
            Далее
          </button>
        </div>
      </div>
      <div
        className={
          isCardDisabled
            ? 'map-editor__card map-editor__step disabled'
            : 'map-editor__card map-editor__step'
        }
      >
        <h2 className="map-editor__step-title">Шаг 4. Наполнение карточки</h2>
        <form>
          <div>
            <TextField
              multiline
              label="Название"
              variant="outlined"
              sx={{
                width: 500,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
              onChange={nameHandler}
              value={name}
            />
          </div>
          <div>
            <TextField
              multiline
              label="Описание"
              variant="outlined"
              sx={{
                width: 500,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
              onChange={descriptionHandler}
              value={description}
            />
          </div>
          <div>
            <TextField
              label="Ссылка на сообщество"
              variant="outlined"
              sx={{
                width: 500,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
              onChange={linkHandler}
              value={link}
            />
          </div>
          <div>
            <TextField
              label="Ссылка на видео"
              variant="outlined"
              sx={{
                width: 500,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
              onChange={videoLinkHandler}
              value={videoLink}
            />
          </div>
        </form>
        <div className="map-editor__btns">
          <button className="map-editor__back-btn" onClick={cancelCardHandler}>
            Назад
          </button>
          <button
            className="map-editor__confirm-btn"
            onClick={(e) => confirmCardHandler(e)}
          >
            Далее
          </button>
        </div>
      </div>
      <Button
        onClick={addDotHandler}
        loading={isLoading}
        variant="contained"
        disabled={isAddBtnDisabled}
        sx={{
          mt: '30px',
          width: 180,
          background: '#2c2c2c',
          boxShadow: 'none',
          borderRadius: '8px',
          height: 45,
          textTransform: 'none',
          fontSize: 16,
          transition: 'opacity 0.3s ease',
          '&:hover': {
            boxShadow: 'none',
            opacity: 0.7,
          },
          '&.Mui-disabled': {
            opacity: 0.2,
            background: '#2c2c2c',
            color: '#fff',
          },
        }}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          'Добавить'
        )}
      </Button>
    </div>
  );
}

export default MapEditor;
