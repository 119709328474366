import React, { useState } from 'react';
import './CardEditor.scss';
import { TextField, Button, CircularProgress } from '@mui/material';
import * as api from '../../api/api';
import { useDispatch } from 'react-redux';
import { updateBroadcast } from '../../store/broadcastsSlice';
import { updateDiscovery } from '../../store/discoveriesSlice';
import { decodeHtml } from '../../utils/decodeHtml';

const CardEditor = ({
  id,
  cardIsActive,
  setCardIsActive,
  name,
  setName,
  description,
  setDescription,
  link,
  setLink,
  videoLink,
  setVideoLink,
  type,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const closeOnWrapperClick = (e) => {
    e.stopPropagation();
    if (e.target.classList.contains('card-editor__wrapper')) {
      setCardIsActive(false);
    }
  };

  const nameHandler = (e) => {
    setName(e.target.value);
  };

  const descriptionHandler = (e) => {
    setDescription(e.target.value);
  };

  const linkHandler = (e) => {
    setLink(e.target.value);
  };

  const videoLinkHandler = (e) => {
    setVideoLink(e.target.value);
  };

  const updateCardHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    if (name) {
      formData.append('name', name);
    }
    if (description) {
      formData.append('description', description);
    }
    if (link) {
      formData.append('link', link);
    }
    if (videoLink) {
      formData.append('video_link', videoLink);
    }

    try {
      if (type === 'broadcast') {
        await dispatch(updateBroadcast({ data: formData, id }));
      } else if (type === 'discovery') {
        await dispatch(updateDiscovery({ data: formData, id }));
      }
    } catch (error) {
      console.error('Ошибка обновления:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cardIsActive ? 'card-editor is-active' : 'card-editor'}>
      <div className="card-editor__wrapper" onMouseDown={closeOnWrapperClick}>
        <div className="card-editor__card" onClick={(e) => e.stopPropagation()}>
          <button
            className="card-editor__close-btn"
            onClick={() => setCardIsActive(false)}
          >
            <img src="./../img/icons/icon-close.svg" alt="Закрыть" />
          </button>
          <h2 className="card-editor__title">Редактор карточки</h2>
          <form>
            <TextField
              multiline
              label="Название"
              variant="outlined"
              value={decodeHtml(name) || ''}
              onChange={nameHandler}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
            />
            <TextField
              multiline
              label="Описание"
              variant="outlined"
              value={decodeHtml(description) || ''}
              onChange={(e) => descriptionHandler(e)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
            />
            <TextField
              multiline
              label="Ссылка на сообщество"
              variant="outlined"
              value={link || ''}
              onChange={(e) => linkHandler(e)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
            />
            <TextField
              multiline
              label="Ссылка на видео"
              variant="outlined"
              value={videoLink || ''}
              onChange={(e) => videoLinkHandler(e)}
              sx={{
                width: '100%',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '8px',
                  '&.Mui-focused fieldset': {
                    borderColor: '#2c2c2c',
                    color: '#2c2c2c',
                    borderWidth: '2px',
                  },
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#2c2c2c',
                },
              }}
            />
            <Button
              loading={isLoading}
              variant="contained"
              onClick={(e) => updateCardHandler(e)}
              sx={{
                mt: '10px',
                width: '100%',
                background: '#2c2c2c',
                boxShadow: 'none',
                borderRadius: '8px',
                height: 50,
                textTransform: 'none',
                fontSize: 16,
                transition: 'opacity 0.3s ease',
                '&:hover': {
                  boxShadow: 'none',
                  opacity: 0.7,
                },
                '&.Mui-disabled': {
                  opacity: 0.2,
                  background: '#2c2c2c',
                  color: '#fff',
                },
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Сохранить изменения'
              )}
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CardEditor;
