import { useState, useEffect } from 'react';
import Partners from '../Partners/Partners';
import * as api from '../../api/api';
import './Footer.scss';

function Footer() {
  const [footerLogo, setFooterLogo] = useState('');

  useEffect(() => {
    (async function () {
      setFooterLogo(JSON.parse((await api.getFooterLogo()).content));
    })();
  }, []);
  return (
    <footer className="footer">
      <div className="footer__top">
        <Partners />
      </div>
      <div className="footer__bottom">
        <div className="container">
          <ul className="footer__list">
            <li className="footer__list-item">
              <a href="#about" className="footer__link">
                О проекте
              </a>
            </li>
            <li className="footer__list-item">
              <a href="#map" className="footer__link">
                Карта
              </a>
            </li>
            <li className="footer__list-item">
              <a href="" className="footer__link">
                Моя страница
              </a>
            </li>
            <li className="footer__list-item">
              <a href="" className="footer__link footer__policy">
                Политика обработки персональных данных
              </a>
            </li>
            <li className="footer__list-item">
              <a target='_blank' href="https://vk.com/v_nauke" className="footer__link">
                <img
                  className="footer__logo"
                  src={
                    process.env.REACT_APP_DOMAIN_NAME +
                    '/' +
                    footerLogo.logo_src
                  }
                  alt="Логотип ВНауке"
                />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
