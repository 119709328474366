// let accessToken = null;

// async function makeRefresh() {
//   const refreshRes = request(`https://api.maksimgolovanovfrontend.ru/refresh`);

//   if (refreshRes.accessToken) {
//     accessToken = refreshRes.accessToken;
//   }
// }

export class HttpError extends Error {
  status;
  text;
  constructor(message, status, text) {
    super(message);
    this.status = status;
    this.text = text;
  }
}

export async function requestWithToken(url, options = {}) {
  if (options.headers === undefined) {
    options.headers = {};
  }

  const token = localStorage.getItem('token');

  options.headers.Authorization = `Bearer ${token}`;

  try {
    return await request(url, options);
  } catch (e) {
    if (e instanceof HttpError && e.status === 401) {
      try {
        const refreshRes = await request(
          `${process.env.REACT_APP_API_URL}/refresh`,
          {
            method: 'POST',
            credentials: 'include',
            // headers: {
            //   'Content-Type': 'application/json',
            // },
          }
        );

        if (refreshRes && refreshRes.accessToken) {
          localStorage.setItem('token', refreshRes.accessToken);
          return await requestWithToken(url, options);
        } else {
          throw new Error('Не удалось обновить токен.');
        }
      } catch (refreshError) {
        console.error('Ошибка при обновлении токена:', refreshError);
        // localStorage.removeItem('token');
        // localStorage.removeItem('role');
        // window.location.href = '/login';
      }
    } else {
      throw e; 
    }
  }
}

export async function request(url, options = {}) {
  if (options.headers === undefined) {
    options.headers = {};
  }

  // options.headers['Content-Type'] = 'application/json';
  try {
    const response = await fetch(url, options);

    if (response.status < 200 || response.status >= 400) {
      throw new HttpError('Status exc', response.status, await response.text());
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}
