import React, { useState } from 'react';
import './DiscoveriesEditor.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDiscoveries,
  deleteDiscovery,
} from '../../store/discoveriesSlice';
import * as api from '../../api/api';
import { Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CardEditor from '../CardEditor/CardEditor';
import { decodeHtml } from '../../utils/decodeHtml';

const DiscoveriesEditor = () => {
  const { discoveries } = useSelector((state) => state.discoveries);
  const [cardIsActive, setCardIsActive] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [id, setId] = useState('');

  const dispatch = useDispatch();

  useState(() => {
    dispatch(fetchDiscoveries());
  });

  const editDiscoveryHandler = async (id) => {
    try {
      const data = await api.getItem(id);
      setName(data.name);
      setDescription(data.description);
      setLink(data.link);
      setVideoLink(data.video_link);
      setCardIsActive(true);
      setId(data.id);
    } catch (error) {
      console.log(error);
    }
  };

  const deleteBtnHandler = (id) => {
    dispatch(deleteDiscovery(id));
  };
  return (
    <div className="discoveries-editor">
      <CardEditor
        cardIsActive={cardIsActive}
        setCardIsActive={setCardIsActive}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        link={link}
        setLink={setLink}
        videoLink={videoLink}
        setVideoLink={setVideoLink}
        id={id}
        type="discovery"
      />
      <h2 className="discoveries-editor__title">Открытия</h2>
      <ul className="discoveries-editor__list">
        <li className="discoveries-editor__list-desc">
          <div>№</div>
          <div>Название</div>
          <div>Описание</div>
          <div>Ссылка на сообщество</div>
          <div>Ссылка на видео</div>
          <div></div>
        </li>
        {discoveries?.map((discovery, index) => (
          <li key={discovery.id} className="discoveries-editor__list-item">
            <div>{index + 1}</div>
            <div>{decodeHtml(discovery.name)}</div>
            <div>{decodeHtml(discovery.description)}</div>
            <div className="discoveries-editor__link">{discovery.link}</div>
            <div>{discovery.video_link}</div>
            <div className="discoveries-editor__btns">
              <Fab
                onClick={() => editDiscoveryHandler(discovery.id)}
                aria-label="Редактировать"
                sx={{
                  background: '#66CCFF',
                  transition: 'opacity 0.3s ease',
                  boxShadow: 'none',
                  zIndex: 1,
                  '&:hover': {
                    opacity: 0.7,
                    backgroundColor: '#66CCFF',
                  },
                }}
              >
                <EditIcon />
              </Fab>
              <Fab
                aria-label="Удалить"
                sx={{
                  backgroundColor: '#2c2c2c',
                  color: 'white',
                  transition: 'opacity 0.3s ease',
                  zIndex: 1,
                  '&:hover': {
                    opacity: 0.7,
                    backgroundColor: '#2c2c2c',
                  },
                  boxShadow: 'none',
                }}
                onClick={() => deleteBtnHandler(discovery.id)}
              >
                <DeleteIcon />
              </Fab>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DiscoveriesEditor;
