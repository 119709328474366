import React, { useEffect, useState } from 'react';
import './BroadcastsEditor.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBroadcasts, deleteBroadcast } from '../../store/broadcastsSlice';
import * as api from '../../api/api';
import { Fab } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CardEditor from '../CardEditor/CardEditor';
import { decodeHtml } from '../../utils/decodeHtml';

const BroadcastsEditor = () => {
  const { broadcasts } = useSelector((state) => state.broadcasts);
  const [cardIsActive, setCardIsActive] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [id, setId] = useState('');

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchBroadcasts());
  }, []);

  const deleteBtnHandler = (id) => {
    dispatch(deleteBroadcast(id));
  };

  const editBroadcastHandler = async (id) => {
    try {
      const data = await api.getBroadcast(id);
      setName(data.name);
      setDescription(data.description);
      setLink(data.link);
      setVideoLink(data.video_link);
      setCardIsActive(true);
      setId(data.id);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="broadcasts-editor">
      <CardEditor
        cardIsActive={cardIsActive}
        setCardIsActive={setCardIsActive}
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
        link={link}
        setLink={setLink}
        videoLink={videoLink}
        setVideoLink={setVideoLink}
        id={id}
        type="broadcast"
      />
      <h2 className="broadcasts-editor__title">Трансляции</h2>
      <ul className="broadcasts-editor__list">
        <li className="broadcasts-editor__list-desc">
          <div>№</div>
          <div>Название</div>
          <div>Описание</div>
          <div>Ссылка на сообщество</div>
          <div>Ссылка на видео</div>
          <div></div>
        </li>
        {broadcasts?.map((broadcast, index) => (
          <li key={broadcast.id} className="broadcasts-editor__list-item">
            <div>{index + 1}</div>
            <div>{decodeHtml(broadcast.name)}</div>
            <div>{decodeHtml(broadcast.description)}</div>
            <div>{broadcast.link}</div>
            <div>{broadcast.video_link}</div>
            <div className="broadcasts-editor__btns">
              <Fab
                onClick={() => editBroadcastHandler(broadcast.id)}
                aria-label="Редактировать"
                sx={{
                  background: '#66CCFF',
                  transition: 'opacity 0.3s ease',
                  boxShadow: 'none',
                  zIndex: 1,
                  '&:hover': {
                    opacity: 0.7,
                    backgroundColor: '#66CCFF',
                  },
                }}
              >
                <EditIcon />
              </Fab>
              <Fab
                aria-label="Удалить"
                sx={{
                  backgroundColor: '#2c2c2c',
                  color: 'white',
                  transition: 'opacity 0.3s ease',
                  zIndex: 1,
                  '&:hover': {
                    opacity: 0.7,
                    backgroundColor: '#2c2c2c',
                  },
                  boxShadow: 'none',
                }}
                onClick={() => deleteBtnHandler(broadcast.id)}
              >
                <DeleteIcon />
              </Fab>
            </div>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default BroadcastsEditor;
