import './AdminSidebar.scss';
import { NavLink, useNavigate } from 'react-router';
import * as api from '../../api/api';
import { logoutUser } from '../../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';

function AdminSidebar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.user);

  async function logout() {
    dispatch(logoutUser());
  }
  return (
    <div className="admin-sidebar">
      <ul className="admin-sidebar__category-list">
        <li className="admin-sidebar__sidebar__category-list-item">
          Редактор контента
          <ul className="admin-sidebar__list">
            <li className="admin-sidebar__list-item">
              {' '}
              <NavLink to="/admin/logo" className="admin-sidebar__list-link">
                Логотип
              </NavLink>
            </li>
            {/* <li className="admin-sidebar__list-item">
              {' '}
              <NavLink to="/admin/about" className="admin-sidebar__list-link">
                О проекте
              </NavLink>
            </li> */}
            <li className="admin-sidebar__list-item">
              {' '}
              <NavLink to="/admin/map" className="admin-sidebar__list-link">
                Карта
              </NavLink>
            </li>
            <li className="admin-sidebar__list-item">
              {' '}
              <NavLink
                to="/admin/broadcasts"
                className="admin-sidebar__list-link"
              >
                Трансляции
              </NavLink>
            </li>
            <li className="admin-sidebar__list-item">
              {' '}
              <NavLink
                to="/admin/discoveries"
                className="admin-sidebar__list-link"
              >
                Открытия
              </NavLink>
            </li>
            <li className="admin-sidebar__list-item">
              {' '}
              <NavLink
                to="/admin/cities"
                className="admin-sidebar__list-link"
              >
                Города
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>

      <button
        className="admin-sidebar__logout-btn"
        type="button"
        onClick={() => logout()}
      >
        <img src="./../img/icons/icon-logout.svg" alt="Выход" />
        Выход
      </button>
    </div>
  );
}

export default AdminSidebar;
