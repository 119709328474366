import React from 'react';
import './Partners.scss';

const Partners = () => {
  return (
    <section className="partners">
      <div className="container">
        <ul className="partners__list">
          <li className="partners__list-item">
            <a
              target="_blank"
              href="https://vk.company/ru/"
              className="partners__link"
            >
              <img
                src="./../img/content/partners/partners-01.svg"
                alt="ВК"
                className="partners__img"
              />
            </a>
          </li>
          <li className="partners__list-item">
            <a
              target="_blank"
              href="https://msu.ru/"
              className="partners__link"
            >
              <img
                src="./../img/content/partners/partners-02.svg"
                alt="МГУ"
                className="partners__img"
              />
            </a>
          </li>
          <li className="partners__list-item">
            <a
              target="_blank"
              href="https://festivalnauki.ru/"
              className="partners__link"
            >
              <img
                src="./../img/content/partners/partners-03.svg"
                alt="НАУКА 0+"
                className="partners__img"
              />
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Partners;
