import './AdminLoginPage.scss';

import AdminAuth from '../../components/AdminAuth/AdminAuth';

function AdminLoginPage() {
  return (
    <section className="admin-login">
      <div className="container">
        <AdminAuth />
      </div>
    </section>
  );
}

export default AdminLoginPage;
