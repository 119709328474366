import { useState, useRef } from 'react';
import './LogoEditor.scss';
import { Button, CircularProgress } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

function LogoEditor() {
  const [selectedHeaderFile, setSelectedHeaderFile] = useState(null);
  const [selectedFooterFile, setSelectedFooterFile] = useState(null);
  // const [uploaded, setUpladed] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fileHeaderPicker = useRef();
  const fileFooterPicker = useRef();

  const handleHeaderChange = (event) => {
    setSelectedHeaderFile(event.target.files[0]);
  };

  const handleFooterChange = (event) => {
    setSelectedFooterFile(event.target.files[0]);
  };

  const handleHeaderUpload = async () => {
    if (!selectedHeaderFile) {
      alert('Выберите файл');
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', selectedHeaderFile);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/editlogo`, {
      method: 'POST',
      body: formData,
    });

    const data = await res.json();
    setIsLoading(false);
    setSelectedHeaderFile(null);
  };

  const handleFooterUpload = async () => {
    if (!selectedFooterFile) {
      alert('Выберите файл');
      return;
    }
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', selectedFooterFile);

    const res = await fetch(`${process.env.REACT_APP_API_URL}/editfooterlogo`, {
      method: 'POST',
      body: formData,
    });

    const data = await res.json();
    setIsLoading(false);
    setSelectedFooterFile(null);
  };

  // const handleHeaderPick = () => {
  //   fileHeaderPicker.current.click();
  // };
  return (
    <div className="logo-editor">
      <div className="logo-editor__edit">
        <h2 className="logo-editor__title">Логотип в шапке сайта</h2>

        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{
            width: 300,
            background: '#6F7BD4',
            boxShadow: 'none',
            borderRadius: '8px',
            height: 50,
            textTransform: 'none',
            fontSize: 16,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              boxShadow: 'none',
              opacity: 0.7,
            },
            '&.Mui-disabled': {
              opacity: 0.2,
              background: '#2c2c2c',
              color: '#fff',
            },
          }}
        >
          Загрузть логотип
          <input
            ref={fileHeaderPicker}
            className="hidden"
            type="file"
            onChange={handleHeaderChange}
            accept="image/*, .png, .jpg, .gif, .webp"
            name="image"
          />
        </Button>
        <input
          ref={fileHeaderPicker}
          className="hidden"
          type="file"
          onChange={handleHeaderChange}
          accept="image/*, .png, .jpg, .gif, .webp"
          name="image"
        />
        {selectedHeaderFile && (
          <ul>
            <li>Файл: {selectedHeaderFile.name}</li>
            <li>Размер: {selectedHeaderFile.size} Б</li>
          </ul>
        )}
        <Button
          onClick={handleHeaderUpload}
          loading={isLoading}
          variant="contained"
          sx={{
            mt: '30px',
            display: 'block',
            width: 180,
            background: '#2c2c2c',
            boxShadow: 'none',
            borderRadius: '8px',
            height: 45,
            textTransform: 'none',
            fontSize: 16,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              boxShadow: 'none',
              opacity: 0.7,
            },
            '&.Mui-disabled': {
              opacity: 0.2,
              background: '#2c2c2c',
              color: '#fff',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Подтвердить'
          )}
        </Button>
      </div>
      <div className="logo-editor__edit">
        <h2 className="logo-editor__title">Логотип в подвале сайта</h2>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          sx={{
            width: 300,
            background: '#6F7BD4',
            boxShadow: 'none',
            borderRadius: '8px',
            height: 50,
            textTransform: 'none',
            fontSize: 16,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              boxShadow: 'none',
              opacity: 0.7,
            },
            '&.Mui-disabled': {
              opacity: 0.2,
              background: '#2c2c2c',
              color: '#fff',
            },
          }}
        >
          Загрузть логотип
          <input
            ref={fileHeaderPicker}
            className="hidden"
            type="file"
            onChange={handleFooterChange}
            accept="image/*, .png, .jpg, .gif, .webp"
            name="image"
          />
        </Button>
        <input
          ref={fileFooterPicker}
          className="hidden"
          type="file"
          onChange={handleFooterChange}
          accept="image/*, .png, .jpg, .gif, .webp"
          name="image"
        />
        {selectedFooterFile && (
          <ul>
            <li>Файл: {selectedFooterFile.name}</li>
            <li>Размер: {selectedFooterFile.size} Б</li>
          </ul>
        )}
        <Button
          onClick={handleFooterUpload}
          loading={isLoading}
          variant="contained"
          sx={{
            mt: '30px',
            display: 'block',
            width: 180,
            background: '#2c2c2c',
            boxShadow: 'none',
            borderRadius: '8px',
            height: 45,
            textTransform: 'none',
            fontSize: 16,
            transition: 'opacity 0.3s ease',
            '&:hover': {
              boxShadow: 'none',
              opacity: 0.7,
            },
            '&.Mui-disabled': {
              opacity: 0.2,
              background: '#2c2c2c',
              color: '#fff',
            },
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Подтвердить'
          )}
        </Button>
      </div>
    </div>
  );
}

export default LogoEditor;
