import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../api/api';

export const fetchCities = createAsyncThunk(
  'cities/fetchCities',
  async function () {
    return await api.getCities();
  }
);

export const updateCity = createAsyncThunk(
  'cities/updateCity',
  async ({ data, id }, { rejectWithValue }) => {
    try {
      const response = await api.updateCity(
        {
          method: 'PATCH',
          body: data,
          credentials: 'include',
        },
        id
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const citiesSlice = createSlice({
  name: 'cities',
  initialState: {
    cities: [],
    status: null,
    error: null,
    isHidden: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCities.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.cities = [];
      })
      .addCase(fetchCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.status = 'resolved';
      })
      .addCase(fetchCities.rejected, (state) => {
        state.status = 'rejected';
        state.cities = [];
      })
      .addCase(updateCity.fulfilled, (state, action) => {
        const updatedCity = action.payload;
        const index = state.cities.findIndex((с) => с.id === updatedCity.id);
        if (index !== -1) {
          state.cities[index] = updatedCity;
        }
      });
  },
});

export default citiesSlice.reducer;
