import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as api from '../api/api';
import { useDispatch } from 'react-redux';

export const fetchBroadcasts = createAsyncThunk(
  'broadcasts/fetchBroadcasts',
  async function () {
    return await api.getBroadcasts();
  }
);

export const deleteBroadcast = createAsyncThunk(
  'broadcasts/deleteBroadcast',
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.deleteBroadcast(
        {
          method: 'DELETE',
          credentials: 'include',
        },
        id
      );
      return { id, status: response.status, message: response.message };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const updateBroadcast = createAsyncThunk(
  'broadcasts/updateBroadcast',
  async ({ data, id }, { rejectWithValue }) => {

    try {
      const response = await api.updateBroadcast(
        {
          method: 'PATCH',
          body: data,
          credentials: 'include',
        },
        id
      );
      return response.data;

    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

const broadcastsSlice = createSlice({
  name: 'broadcasts',
  initialState: {
    broadcasts: [],
    status: null,
    error: null,
    isHidden: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBroadcasts.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.broadcasts = [];
      })
      .addCase(fetchBroadcasts.fulfilled, (state, action) => {
        state.broadcasts = action.payload;
        state.status = 'resolved';
      })
      .addCase(fetchBroadcasts.rejected, (state) => {
        state.status = 'rejected';
        state.broadcasts = [];
      })
      .addCase(deleteBroadcast.fulfilled, (state, action) => {
        if (action.payload.status) {
          state.broadcasts = state.broadcasts.filter(
            (broadcast) => broadcast.id !== action.payload.id
          );
        }
      })
      .addCase(updateBroadcast.fulfilled, (state, action) => {
        const updatedBroadcast = action.payload;
        const index = state.broadcasts.findIndex(
          (b) => b.id === updatedBroadcast.id
        );
        if (index !== -1) {
          state.broadcasts[index] = updatedBroadcast; // Обновляем конкретную трансляцию
        }
      });
  },
});

export default broadcastsSlice.reducer;
